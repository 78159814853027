import { Box, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { useEffect } from "react";
import { l_home } from "../../locales";
import HomeTypography from "../../theme/HomeTypography";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const UXDrivenSoftwareHouse = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    gsap.to(".ux-driven-purple-lemon", {
      scrollTrigger: {
        trigger: ".ux-driven-purple-lemon",
        start: "top center",
        scrub: 2,
      },
      rotate: 196,
      x: 100,
      y: 20,
    });
    gsap.to(".ux-driven-orange-pill", {
      scrollTrigger: {
        trigger: ".ux-driven-orange-pill",
        start: "top center",
        scrub: 2,
      },
      y: -190,
    });
  }, []);

  return (
    <Box
      sx={{
        width: "90%",
        margin: "auto",
        position: "relative",
        maxWidth: "1800px",
      }}
    >
      <Box sx={{ maxWidth: "1450px", width: { md: "95%", xs: "100%" } }}>
        <HomeTypography
          variant="title2"
          sx={{
            // fontSize: { lg: "121px", md: "84px", xs: "64px" },
            fontSize: { lg: "96px", md: "64px", sm: "48px", xs: "32px" },
            lineHeight: { lg: "130px", md: "94px", sm: "60px", xs: "44px" },
          }}
        >
          {l_home("UxDriven.text0")}
          <br />
          {l_home("UxDriven.text1")}
          <HomeTypography
            variant="span"
            sx={{
              fontFamily: "metal",
              fontWeight: 400,
              fontSize: { lg: "121px", md: "84px", sm: "64px", xs: "42px" },
            }}
          >
            {l_home("UxDriven.textDesign")}
          </HomeTypography>
          {l_home("UxDriven.text2")}
          <HomeTypography
            variant="span"
            sx={{ fontFamily: "fira code", fontWeight: 400 }}
          >
            {l_home("UxDriven.textCode")}
          </HomeTypography>
          {l_home("UxDriven.text3")}
        </HomeTypography>
      </Box>

      <Box
        sx={{
          position: "absolute",
          display: { md: "block", xs: "none" },
          width: { md: "150px", lg: "190px", xl: "220px" },
          top: "-30px",
          right: 0,
          transform: "rotate(143deg)",
        }}
        className="ux-driven-purple-lemon"
      >
        <StaticImage alt="" src="../../images/purple-lemon.svg" width={220} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          display: { md: "block", xs: "none" },
          width: { md: "60px", lg: "85px", xl: "90" },
          top: "-200px",
          right: "20%",
        }}
        className="ux-driven-orange-pill"
      >
        <StaticImage alt="" src="../../images/orange-pill.svg" width={90} />
      </Box>
    </Box>
  );
};

export default UXDrivenSoftwareHouse;
