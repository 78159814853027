import Footer from "../../components/shared/Footer/Footer";
import BelieveInUs from "./components/BelieveInUs/BelieveInUs";
import CaseStudies from "./components/CaseStudies/CaseStudies";
import Title from "./components/Title/Title";
import UXDrivenSoftwareHouse from "./components/UXDrivenSoftwareHouse/UXDrivenSoftwareHouse";
import WhatWeDo from "./components/WhatWeDo/WhatWeDo";

const Home = () => {
  return (
    <>
      <Title />
      <CaseStudies />
      <UXDrivenSoftwareHouse />
      <WhatWeDo />
      <BelieveInUs />
      <Footer withAwwwards />
    </>
  );
};

export default Home;
