import { Box, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect, useRef } from "react";

const Decorations = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  gsap.registerPlugin(ScrollTrigger);
  const animationsRef = useRef<Tween[]>([]);
  useEffect(() => {
    animationsRef.current.forEach((animation) => {
      animation.scrollTrigger?.kill();
      animation.kill();
    });

    animationsRef.current[0] = gsap.to(".header-orange-circle", {
      scrollTrigger: {
        trigger: ".header-orange-circle",
        start: isOverMd ? "bottom 25%" : "top 5%",
        end: "+=200",
        // markers: true,
        scrub: 2,
      },
      x: isOverMd ? 150 : 50,
      y: isOverMd ? 80 : 40,
    });

    animationsRef.current[1] = gsap.to(".header-blue-lemon", {
      scrollTrigger: {
        trigger: ".header-blue-lemon",
        // markers: true,
        scrub: 2,
        start: isOverMd ? "bottom 40%" : "top 20%",
        end: "+=200",
      },
      x: isOverMd ? 50 : -20,
      y: isOverMd ? 120 : 90,
      rotate: 190,
    });
    animationsRef.current[2] = gsap.to(".header-purple-lemon", {
      scrollTrigger: {
        trigger: ".header-purple-lemon",
        scrub: 2,
        start: isOverMd ? "bottom 80%" : "top 40%",
        end: "+=200",
        // markers: true,
      },
      x: isOverMd ? -40 : 10,
      y: isOverMd ? 60 : 80,
      rotate: -220,
    });
  }, [isOverMd]);

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          width: { lg: "76px", md: "50px", sm: "32px", xs: "28px" },
          top: "15%",
          right: "20%",
          zIndex: -1,
        }}
        className="header-orange-circle"
      >
        <StaticImage alt="" src="../../images/orange-circle.svg" width={76} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          width: { lg: "170px", md: "104px", sm: "80px", xs: "54px" },
          top: "40%",
          right: "0%",
          transform: "rotate(148deg)",
          zIndex: -1,
        }}
        className="header-blue-lemon"
      >
        <StaticImage alt="" src="../../images/blue-lemon.svg" width={170} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          width: { lg: "170px", md: "104px", sm: "80px", xs: "54px" },
          bottom: "0%",
          right: "10%",
          transform: "rotate(-140deg)",
          zIndex: -1,
        }}
        className="header-purple-lemon"
      >
        <StaticImage alt="" src="../../images/purple-lemon.svg" width={170} />
      </Box>
    </>
  );
};

export default Decorations;
