import { keyframes } from "@emotion/react";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { l_home } from "../../locales";
import HomeTypography from "../../theme/HomeTypography";

const Subtitle = () => {
  const weLikeList = [
    l_home("title.subtitle1"),
    l_home("title.subtitle2"),
    l_home("title.subtitle3"),
    l_home("title.subtitle4"),
    l_home("title.subtitle5"),
    l_home("title.subtitle6"),
    l_home("title.subtitle7"),
    l_home("title.subtitle8"),
    l_home("title.subtitle9"),
    l_home("title.subtitle10"),
    l_home("title.subtitle11"),
    l_home("title.subtitle12"),
  ];

  const [phraseIndex, setPhraseIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setPhraseIndex((i) => (i + 1) % weLikeList.length);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box display="flex" flexDirection="row" flexWrap="nowrap">
      <HomeTypography
        variant="body1"
        sx={{ whiteSpace: "pre-wrap", flexShrink: 0 }}
      >
        {l_home("title.subtitlePartGeneric")}
      </HomeTypography>

      <Box
        sx={{
          position: "relative",
        }}
      >
        <Phrase
          key={"s1" + phraseIndex}
          isEntering
          text={weLikeList[(phraseIndex + 1) % weLikeList.length]}
        />
        <Phrase key={"s2" + phraseIndex} text={weLikeList[phraseIndex]} />
      </Box>
    </Box>
  );
};

const Phrase = (props: { isEntering?: boolean; text: string }) => {
  const exitAnim = keyframes`
    0% {
        transform: perspective(500px) rotateX(0deg) scale(1);
        opacity: 1
    }

    100% {
        transform: perspective(500px) rotateX(90deg) scale(0.9);
        opacity: 0.5
    }
`;

  const enterAnim = keyframes`
    0% {
        transform: perspective(500px) rotateX(-90deg) scale(0.9);
        /* opacity: 0 */
        opacity: 0
    }

    100% {
        transform: perspective(500px) rotateX(0deg) scale(1);
        opacity: 1
    }
`;

  const durationMillis = 600;

  return (
    <Box
      sx={{
        display: "flex",
        transformStyle: "preserve-3d",
        whiteSpace: "nowrap",
        // background: "cyan",

        ...(props.isEntering
          ? {
              transformOrigin: "25% 140%",
              animation: `${enterAnim} ${durationMillis}ms ease-in forwards`,

              position: "absolute",
              top: 0,
              left: 0,
            }
          : {
              transformOrigin: "25% -10%",
              animation: `${exitAnim} ${durationMillis}ms ease-out forwards`,
            }),
      }}
    >
      <HomeTypography variant="body1" children={props.text} />
    </Box>
  );
};

export default Subtitle;
