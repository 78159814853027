/** @jsxImportSource @emotion/react */
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { ReactNode, useEffect } from "react";
import ButtonWithUnderline from "../../../../components/shared/ButtonWithUnderline";
import { l_home } from "../../locales";
import HomeTypography from "../../theme/HomeTypography";

// @ts-ignore
import video_nowr from "../../video/nowr_video.mp4";
// @ts-ignore
import video_sgeeks from "../../video/sgeeks_video.mp4";
// @ts-ignore
import video_isatra from "../../video/isatra_video.mp4";
// @ts-ignore
import video_bonusx from "../../video/bonusx_video.mp4";

const SingleCaseStudy = ({
  caseStudy,
  index,
}: {
  caseStudy: HomeCaseStudy;
  index: number;
}) => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"), {
    noSsr: false,
    defaultMatches: true,
  });

  const {
    video,
    title: _title,
    description,
    logo,
    link,
    decorations,
  } = caseStudy;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { md: "row", xs: "column" },
        gap: { xs: "24px", md: "32px" },
        justifyContent: {
          md: "space-between",
          xs: "center",
        },
        alignItems: {
          xs: "flex-start",
          md: "flex-end",
        },
        width: { md: "100%", xs: "60%" },
        minWidth: { xs: "300px", md: "unset" },
        alignSelf: index % 2 === 0 ? "flex-start" : "flex-end",
        position: "relative",
      }}
    >
      <Box
        sx={{
          // backgroundColor: "red",
          width: { md: index === 3 ? "45%" : "40%", xs: "100%" },
          flexShrink: 0,
          overflow: "hidden",
          // backgroundColor: "red",
          order: !isOverMd ? 1 : index % 2 === 0 ? 2 : 1,
        }}
      >
        <Box
          sx={{
            fontSize: 0,
            // *  NON TOCCARE: È IL PLACEHOLDER DEL VIDEO
            // width: { md: ind === 3 ? "45%" : "40%", xs: "100%" },
            // flexShrink: 0,
            // overflow: "hidden",
            // // vedere se serve una min width
            // backgroundColor: "lightsalmon",
            // borderRadius: "60px",
            // aspectRatio: ind % 3 === 0 ? "1 / 1.2" : "1 / 1.1", // simula video di altezze diverse
          }}
        >
          {/* <div css={{width: "100%", height: 200, background: "red"}}></div> */}
          <video
            width={"100%"}
            autoPlay={true}
            loop={true}
            muted={true}
            controls={false}
            playsInline={true}
            css={{
              padding: "0px",
              pointerEvents: "none",
              aspectRatio: String(video.width / video.height),
            }}
          >
            <source src={video.url} type="video/mp4" />
          </video>
        </Box>
      </Box>

      <Box
        sx={{
          order: !isOverMd ? 2 : index % 2 === 0 ? 1 : 2,
          maxWidth: "640px",
        }}
      >
        <HomeTypography
          variant="title1"
          sx={{ marginBottom: { md: "76px", xs: "16px" } }}
        >
          {description}
        </HomeTypography>

        <Stack
          sx={{
            flexDirection: { md: "row", xs: "column" },
            justifyContent: "space-between",
            width: "100%",
            alignItems: { md: "center", xs: "flex-start" },
            gap: { md: "32px", xs: "16px" },
          }}
        >
          <Box sx={{ maxWidth: "220px" }}>{logo}</Box>
          <Box sx={{ flexShrink: 0, minWidth: "200px" }}>
            <ButtonWithUnderline
              defaultUnderlined
              link={link}
              children={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "4px",
                  }}
                >
                  <HomeTypography variant="body1" sx={{ color: "black" }}>
                    {l_home("caseStudies.buttonText")}
                  </HomeTypography>
                  <Box
                    sx={{
                      width: { md: "24px", xs: "20px" },
                      marginLeft: { md: "16px", xs: "8px" },
                      marginTop: "4px",
                    }}
                  >
                    <StaticImage alt="" src="../../images/arrow-icon.svg" />
                  </Box>
                </Box>
              }
            />
          </Box>
        </Stack>
      </Box>
      {isOverMd && <>{decorations}</>}
    </Box>
  );
};

const CaseStudies = () => {
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    gsap.to(".case-studies-purple-circle", {
      scrollTrigger: {
        trigger: ".case-studies-purple-circle",
        scrub: 2,
        start: "center 80%",
        end: "+=300",
      },
      x: 100,
      y: 80,
    });
    gsap.to(".case-studies-orange-lemon", {
      scrollTrigger: {
        trigger: ".case-studies-purple-circle",
        scrub: 2,
        start: "center 80%",
        end: "+=300",
      },
      rotate: 120,
      x: 100,
      y: -70,
    });
    gsap.to(".case-studies-purple-pill", {
      scrollTrigger: {
        trigger: ".case-studies-purple-pill",
        scrub: 2,
        start: "center 80%",
        end: "+=300",
      },
      x: 120,
    });
    gsap.to(".case-studies-blue-lemon", {
      scrollTrigger: {
        trigger: ".case-studies-blue-lemon",
        scrub: 2,
        start: "center 80%",
        end: "+=300",
      },
      x: 80,
      rotate: -200,
      y: -20,
    });
  }, []);

  const caseStudies: HomeCaseStudy[] = [
    {
      title: "bonusX",
      description: l_home("caseStudies.description1"),
      logo: (
        <StaticImage
          src={"../../images/bonus-x-logo.png"}
          width={240}
          alt={""}
        />
      ),
      link: "/works/bonusx",
      video: { url: video_bonusx, width: 719, height: 705 },
      decorations: (
        <>
          <Box
            sx={{
              position: "absolute",
              width: "50px",
              bottom: "-150px",
              left: "40%",
            }}
            className="case-studies-purple-circle"
          >
            <StaticImage
              src={"../../images/purple-circle.svg"}
              width={50}
              alt={""}
            />
          </Box>
        </>
      ),
    },
    {
      title: "sGeeks",
      description: l_home("caseStudies.description2"),
      logo: (
        <StaticImage
          src={"../../images/sgeeks-logo.png"}
          width={240}
          alt={""}
        />
      ),
      link: "/works/startup-geeks",
      video: { url: video_sgeeks, width: 720, height: 894 },
      decorations: (
        <>
          <Box
            sx={{
              position: "absolute",
              width: "105px",
              transform: "rotate(148deg)",
              top: "-80px",
              left: "60%",
            }}
            className="case-studies-orange-lemon"
          >
            <StaticImage
              src={"../../images/orange-lemon.svg"}
              width={105}
              alt={""}
            />
          </Box>
        </>
      ),
    },
    {
      title: "nowr",
      description: l_home("caseStudies.description3"),
      logo: (
        <StaticImage src={"../../images/nowr-logo.png"} width={240} alt={""} />
      ),
      link: "/works/nowr",
      video: { url: video_nowr, width: 720, height: 799 },
      decorations: (
        <>
          <Box
            sx={{
              position: "absolute",
              width: "45px",
              transform: "rotate(90deg)",
              top: "-80px",
              left: "10%",
            }}
            className="case-studies-purple-pill"
          >
            <StaticImage
              src={"../../images/purple-pill.svg"}
              width={45}
              alt={""}
            />
          </Box>
        </>
      ),
    },
    {
      title: "isatra",
      description: l_home("caseStudies.description4"),
      logo: (
        <StaticImage
          src={"../../images/isatra-logo.png"}
          width={240}
          alt={""}
        />
      ),
      link: "/works/intesa-sanpaolo-assicura",
      video: { url: video_isatra, width: 720, height: 593 },
      decorations: (
        <>
          <Box
            sx={{
              position: "absolute",
              width: "105px",
              transform: "rotate(-132deg)",
              top: "-100px",
              left: "50%",
            }}
            className="case-studies-blue-lemon"
          >
            <StaticImage
              src={"../../images/blue-lemon.svg"}
              width={105}
              alt={""}
            />
          </Box>
        </>
      ),
    },
  ];

  return (
    <Box
      sx={{
        paddingTop: { md: "450px", xs: "96px" },
        marginBottom: { md: "630px", xs: "96px" },
        display: "flex",
        flexDirection: "column",
        gap: { md: "400px", xs: "72px" },
        width: "90%",
        margin: "auto",
        maxWidth: "1800px",
        // backgroundColor: "lightseagreen",
      }}
    >
      {caseStudies.map((casestudy, i) => (
        <SingleCaseStudy
          key={casestudy.title}
          caseStudy={casestudy}
          index={i}
        />
      ))}
    </Box>
  );
};

export default CaseStudies;

interface HomeCaseStudy {
  title: string;
  description: string;
  logo: ReactNode;
  link: string;
  video: { url: string; width: number; height: number };
  decorations: ReactNode;
}
