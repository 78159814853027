function ButtonRoundArrowIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="prefix__buttonroundarrow"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={25}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 12.5c0 5.523 4.477 10 10 10s10-4.477 10-10c0-5.522-4.478-10-10-10S2 6.978 2 12.5zm1.5 0a8.5 8.5 0 1117.002.002A8.5 8.5 0 013.5 12.5zm6.456 3.024a.75.75 0 001.13.979l3.487-3.471.073-.085a.75.75 0 00-.073-.978l-3.486-3.471-.084-.073a.75.75 0 00-.977.075l-.072.084a.75.75 0 00.074.977l2.952 2.94-2.952 2.939-.072.084z"
          fill="#000"
        />
      </mask>
      <g mask="url(#prefix__buttonroundarrow)">
        <path fill="#fff" d="M0 .5h24v24H0z" />
      </g>
    </svg>
  );
}

export default ButtonRoundArrowIcon;
