import { Box } from "@mui/material";
import { l_home } from "../../locales";
import HomeTypography from "../../theme/HomeTypography";
import Decorations from "./Decorations";
import Subtitle from "./Subtitle";

const Title = () => {
  return (
    <Box
      sx={{
        width: "90%",
        maxWidth: "1800px",
        margin: "auto",
        paddingTop: { md: "108px", xs: "56px" },
        position: "relative",
        isolation: "isolate",
      }}
    >
      <Box
        sx={{
          // maxWidth: { xl: "75%", lg: "75%", md: "80%", xs: "70%" },
          minWidth: "350px",
          marginBottom: { md: "64px", xs: "16px" },
          zIndex: 3,
          isolation: "isolate",
        }}
      >
        <HomeTypography variant="bigTitle" sx={{ zIndex: 3 }}>
          {l_home("title.title1")}
          <br />
          {l_home("title.title2")}
          <br />
          {l_home("title.title3")}
          <br />
          {l_home("title.title4")}
        </HomeTypography>
      </Box>
      <Decorations />
      <Subtitle />
    </Box>
  );
};

export default Title;
