/** @jsxImportSource @emotion/react */
import { Box, Button, alpha, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { l_home } from "../../locales";
import HomeTypography from "../../theme/HomeTypography";
import { WhatWeDoList, WhatWeDoListSmall } from "./WhatWeDoList";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect, useRef, useState } from "react";
import ButtonRoundArrowIcon from "../../theme/ButtonRoundArrowIcon";
import { LocalizedLink } from "../../../../components/LocalizedLink";

//@ts-ignore
import img1 from "../../images/whatwedo-1.png";
//@ts-ignore
import img2 from "../../images/whatwedo-2.png";
//@ts-ignore
import img3 from "../../images/whatwedo-3.png";
//@ts-ignore
import img4 from "../../images/whatwedo-4.png";
import { usePage } from "../../../../layouts";

const WhatWeDo = () => {
  gsap.registerPlugin(ScrollTrigger);
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  const listItems = [
    {
      title: l_home("whatWeDo.listItem1Title"),
      description: l_home("whatWeDo.listItem1Description"),
      imgSrc: img1,
      image: (
        <Box
          sx={{
            aspectRatio: "1 / 0.77",
            overflow: "hidden",
            borderRadius: "24px",
            maxWidth: "530px",
            minWidth: "300px",
            // backgroundColor: "red",
          }}
        >
          <StaticImage
            src={"../../images/whatwedo-1-sm.png"}
            alt={""}
            width={530}
            imgStyle={{ borderRadius: "24px" }}
          />
        </Box>
      ),
    },
    {
      title: l_home("whatWeDo.listItem2Title"),
      description: l_home("whatWeDo.listItem2Description"),
      imgSrc: img2,

      image: (
        <Box
          sx={{
            aspectRatio: "1 / 1.2",
            overflow: "hidden",
            borderRadius: "24px",
            maxWidth: "435px",
            minWidth: "230px",
            marginLeft: "auto",
            // backgroundColor: "red",
          }}
        >
          <StaticImage
            src={"../../images/whatwedo-2-sm.png"}
            alt={""}
            width={435}
            imgStyle={{ borderRadius: "24px" }}
          />
        </Box>
      ),
    },
    {
      title: l_home("whatWeDo.listItem3Title"),
      description: l_home("whatWeDo.listItem3Description"),
      imgSrc: img3,

      image: (
        <Box
          sx={{
            aspectRatio: "1 / 0.82",
            overflow: "hidden",
            borderRadius: "24px",
            maxWidth: "600px",
            minWidth: "305px",
            // backgroundColor: "red",
          }}
        >
          <StaticImage
            src={"../../images/whatwedo-3-sm.png"}
            alt={""}
            width={600}
            imgStyle={{ borderRadius: "24px" }}
          />
        </Box>
      ),
    },
    {
      title: l_home("whatWeDo.listItem4Title"),
      description: l_home("whatWeDo.listItem4Description"),
      imgSrc: img4,
      image: (
        <Box
          sx={{
            aspectRatio: "1 / 0.88",
            overflow: "hidden",
            borderRadius: "24px",
            maxWidth: "530px",
            minWidth: "245px",
            marginLeft: "auto",
            // backgroundColor: "red",
          }}
        >
          <StaticImage
            src={"../../images/whatwedo-4-sm.png"}
            alt={""}
            width={530}
            imgStyle={{ borderRadius: "24px" }}
          />
        </Box>
      ),
    },
  ];

  const [activeListItem, setActiveListItem] = useState(0);
  const activeListItemDeounceTimer = useRef<NodeJS.Timeout | null>(null);
  const debounceActiveListItem = (index: number) => {
    if (activeListItemDeounceTimer.current) {
      clearTimeout(activeListItemDeounceTimer.current);
    }
    activeListItemDeounceTimer.current = setTimeout(() => {
      setActiveListItem(index);
    }, 10);
  };

  const [ctx] = useState(gsap.context(() => {}));

  useEffect(() => {
    let timelineTimeout: NodeJS.Timeout;
    if (isOverMd) {
      timelineTimeout = setTimeout(() => {
        ctx.add(() => {
          return gsap.timeline({
            scrollTrigger: {
              trigger: ".whatwedo-list-container",
              pin: isOverMd ? true : false,
              start: "top top",
              end: "+=" + listItems.length * 1000,
              onUpdate: (trigger) => {
                debounceActiveListItem(
                  Math.max(
                    0,
                    Math.ceil(trigger.progress / (1 / listItems.length)) - 1
                  )
                );
              },
            },
          });
        });
      }, 200);
    }
    return () => {
      clearTimeout(timelineTimeout);
      ctx.revert();
    };
  }, [isOverMd]);

  useEffect(() => {
    gsap.to(".whatwedo-orange-lemon", {
      scrollTrigger: {
        trigger: ".whatwedo-orange-lemon",
        start: "center 20%",
        end: "+=1500",
        scrub: 2,
        // markers: true,
      },
      rotate: 168,
      y: 150,
      x: 100,
    });
    gsap.to(".whatwedo-blue-lemon", {
      scrollTrigger: {
        trigger: ".whatwedo-orange-lemon",
        start: "center 20%",
        end: "+=1500",
        scrub: 2,
        // markers: true,
      },
      rotate: -218,
      y: 50,
      x: 120,
    });
    gsap.fromTo(
      ".whatwedo-purple-circle",
      { x: -50 },
      {
        scrollTrigger: {
          trigger: ".whatwedo-orange-lemon",
          start: "center 20%",
          end: "+=1500",
          scrub: 2,
          // markers: true,
        },
        x: 80,
        y: 120,
      }
    );
  }, []);

  const page = usePage();

  return (
    <Box>
      <Box
        className="pinSpacerParent"
        sx={{
          marginTop: { md: "330px", xs: "96px" },
          width: "90%",
          margin: "auto",
          maxWidth: "1500px",
          position: "relative",
          // backgroundColor: "orchid",
        }}
      >
        <Box
          sx={{
            display: { xs: "block", md: "flex" },
            height: { xs: "auto", md: "100vh" },
            paddingY: { xs: 0, md: "32px", lg: "40px", xl: "64px" },
            boxSizing: "border-box",
          }}
          className={"whatwedo-list-container"}
        >
          <Box
            sx={{
              display: { md: "block", xs: "none" },
              position: "absolute",
              transform: "rotate(135deg)",
              width: { xl: "250px", lg: "200px", md: "125px" },
              top: "0%",
              right: 0,
            }}
            className="whatwedo-orange-lemon"
          >
            <StaticImage
              alt=""
              src="../../images/orange-lemon.svg"
              width={250}
            />
          </Box>
          <Box
            sx={{
              display: { md: "block", xs: "none" },

              position: "absolute",
              transform: "rotate(-140deg)",
              width: { xl: "230px", lg: "190px", md: "120px" },
              bottom: "20%",
              right: "55%",
            }}
            className="whatwedo-blue-lemon"
          >
            <StaticImage alt="" src="../../images/blue-lemon.svg" width={250} />
          </Box>
          <Box
            sx={{
              position: "absolute",
              display: { md: "block", xs: "none" },

              width: { xl: "95px", lg: "85px", md: "75px" },
              bottom: "50%",
              right: "-20px",
            }}
            className="whatwedo-purple-circle"
          >
            <StaticImage
              alt=""
              src="../../images/purple-circle.svg"
              width={100}
            />
          </Box>

          {isOverMd && (
            <>
              <Box
                sx={{
                  flexShrink: 0,
                  marginRight: "32px",
                  display: "flex",
                  flexDirection: "column",
                  // background: "lightblue",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <HomeTypography
                    variant="overtitle"
                    sx={{ lineHeight: "20px" }}
                  >
                    {l_home("whatWeDo.title")}
                  </HomeTypography>
                  <Box sx={{ paddingTop: "16px" }}>
                    {listItems.map((listItem, i) => {
                      return (
                        <Box
                          key={listItem?.title}
                          sx={{
                            width: "fit-content",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            const list = document.querySelector(".pin-spacer");

                            const body = document.body;
                            const docEl = document.documentElement;
                            const scrollTop =
                              window.pageYOffset ||
                              docEl.scrollTop ||
                              body.scrollTop;

                            const top =
                              (list?.getBoundingClientRect().top ?? 0) +
                              scrollTop;

                            window.scrollTo({
                              top: top + 1001 * i,
                              // top: top + Math.max(1100, window.innerHeight) * i,
                              // behavior: "smooth",
                            });
                          }}
                        >
                          <HomeTypography
                            variant="semiTitle"
                            sx={{
                              marginBottom: "16px",
                              color:
                                i === activeListItem
                                  ? "rgb(232, 83, 34)"
                                  : "black",
                              transition: "color 200ms ease-in",
                              zIndex: 2,
                            }}
                          >
                            {listItem?.title}
                          </HomeTypography>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
                {!page.location.search.includes("campaign=") && (
                  <LocalizedLink
                    to="/contacts"
                    css={{ textDecoration: "none" }}
                  >
                    <Button
                      disableRipple
                      sx={{
                        textTransform: "none",
                        backgroundColor: "black",
                        borderRadius: "32px",
                        paddingX: "24px",
                        gap: "8px",
                        ":hover": {
                          backgroundColor: alpha("#000", 0.72),
                        },
                      }}
                    >
                      <HomeTypography
                        variant="body1"
                        sx={{ fontWeight: 600, color: "white" }}
                      >
                        {l_home("whatWeDo.button")}
                      </HomeTypography>
                      <ButtonRoundArrowIcon />
                    </Button>
                  </LocalizedLink>
                )}
              </Box>
            </>
          )}
          <Box
            sx={{
              marginLeft: "auto",
              position: { xs: "static", md: "relative" },
              flex: 1,
              maxWidth: { md: "650px", xs: "auto" },
            }}
          >
            <WhatWeDoListSmall listItems={listItems} />
            <WhatWeDoList
              listItems={listItems}
              activeListItem={activeListItem}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WhatWeDo;
