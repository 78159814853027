import HomeScreen from "../features/homepage";
import { graphql, HeadProps } from "gatsby";
import SEOHead, { initHeadLanguage } from "../components/old/seo_head";
import { l_home } from "../features/homepage/locales";

export default HomeScreen;

export const Head = (headProps: HeadProps) => {
  initHeadLanguage(headProps);

  return (
    <SEOHead
      title={l_home("seo.title")}
      description={l_home("seo.description")}
      headProps={headProps}
    />
  );
};

export const query = graphql`
  query HomeMainQuery($localeRegex: String) {
    allContentfulArticle(
      limit: 3
      filter: { node_locale: { regex: $localeRegex }, isVisible: { eq: true } }
      sort: { order: DESC, fields: publishedAt }
    ) {
      edges {
        node {
          id
          slug
          title
          isVisible
          updatedAt
          publishedAt
          category {
            title
            fullname
          }
          foregroundImage {
            fluid(maxWidth: 1000, quality: 95) {
              ...GatsbyContentfulFluid_withWebp
              ...GatsbyContentfulFluid
            }
          }
          author {
            name
            surname
            role
          }
        }
      }
    }
  }
`;
